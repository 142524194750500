var app = new Vue({
  el: '#app',
  data: {
    navShow: true,
    nav: false,
    modal: false,
    movie: false,
    youtube: '',
    load: true,
    opening: true,
    opening1: false,
    opening2: false,
    opening3: false,
    modalsrc: '/assets/images/fig-modal1.png'
  },
  methods: {
    modal: function (oc) {
      if(oc=="open"){
        this.movie = true;
        this.youtube = '<iframe src="https://www.youtube.com/embed/GdzOTg6eEMA?controls=0&autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
      }else if(oc=="close"){
        this.movie = false;
        this.youtube = '';
      }
    }
  },
  mounted() {
    window.onload = () => {
      this.load = false;
      setTimeout(() => {
          this.opening2 = true;
      }, 500);
      setTimeout(() => {
        this.opening1 = true;
      }, 800);
      setTimeout(() => {
          this.opening3 =  true
      }, 1600);
    };
  },
  methods: {
    // share() {
    //   const text = encodeURIComponent(this.textTwitter);
    //   const url = 'https://bonyu.me/';
    //   this.linkTwitter = 'https://twitter.com/intent/tweet?text=' + text +'&amp;hashtags='+ '&amp;url=' + url;
    //   this.linkFacebook = 'https://www.facebook.com/sharer/sharer.php?u=' + url;
    //   this.linkLine = 'http://line.me/R/msg/text/?' + url;
    // },

    navChange(){
      this.nav = !this.nav;
    },
    showModalC(src){
      this.modal = !this.modal;
      this.modalsrc = src;
      // this.load();
    },
    closeModalC(){
      this.modal = !this.modal;
    },
    load: function() {
      console.log('loaded');
    },
  },
})

app.$watch('modalsrc', function(val, oldVal) {
  console.log(val);
});


var easeInOutCubic = function(t, b, c, d) {
  t /= d / 2;
  if (t < 1)
    return c / 2 * t * t * t + b;
  t -= 2;
  return c / 2 * (t * t * t + 2) + b;
};

(function() {

  var ss = document.querySelectorAll('a[href^="#"]');

  if (ss.length > 0) {
    for (var i = 0; i < ss.length; i++) {
      ss[i].addEventListener('click', smoothscroll, false);
    }
  }

  function smoothscroll(e) {
    e.preventDefault();

    // アンカーを取得
    var href = this.getAttribute('href');
    href = href.split('#');
    href = href[1];

    // アンカーの縦位置取得
    var bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    var target = document.getElementById(href);
    var targetTop = target.getBoundingClientRect().top + bodyScrollTop;

    // if( getW() > 750){
    // 	targetTop = targetTop -72;
    // 	if(targetTop < 0){
    // 		targetTop = 0;
    // 	}
    // }

    var contentH = document.body.getBoundingClientRect().bottom;

    var winH = window.innerHeight;
    var y = bodyScrollTop || 0;

    var FPS = 30;
    var TIME = 500;

    var t = 0; // t : 時間(進行度)
    var b = y; // b : 開始の値(開始時の座標やスケールなど)
    var c = targetTop - y; // c : 開始と終了の値の差分
    var d = TIME / FPS; // d : Tween(トゥイーン)の合計時間

    var scrollable = true;

    function scroll() {
      if (!scrollable) {
        removeCancelEvent();
        return false;
      }
      y = easeInOutCubic(t, b, c, d);
      window.scrollTo(0, y);
      if (t < d) {
        t++;
        window.setTimeout(scroll, 1000 / FPS);
      } else {
        removeCancelEvent();
      }
    }

    // マウスホイールのイベントを検知。
    window.addEventListener('mousewheel', cancel, false);
    function cancel() {
      scrollable = false;
    }
    function removeCancelEvent() {
      window.removeEventListener('mousewheel', cancel, false);
    }
    scroll();
  }

})();
